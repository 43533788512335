import React, { useState, useEffect, useCallback } from 'react';
import {
  EuiHeader,
  EuiHeaderLinks,
  EuiHeaderSectionItemButton,
  EuiAvatar,
  EuiText,
  EuiPopover,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPageHeader,
  EuiTitle,
  EuiPageTemplate,
  EuiStat,
  EuiBadge,
  EuiSpacer,
  EuiButton,
  EuiHorizontalRule,
  EuiBasicTableColumn,
  EuiPanel,
  EuiBasicTable,
  Criteria,
  EuiDatePicker,
  EuiPortal,
  EuiToolTip,
  EuiButtonIcon,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButtonEmpty,
  EuiToast,
  EuiConfirmModal,
  EuiOverlayMask,
  EuiCodeBlock,
  EuiSelect,
} from '@elastic/eui';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { removeToken, getUsername } from '../utils/auth';
import api from '../utils/api';


const HomePage: React.FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [username, setUsername] = useState('User');
  const [serverStatus, setServerStatus] = useState(false);
  const [serverIp, setServerIp] = useState('');
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [toasts, setToasts] = useState<Array<{ id: string; title: string; color: 'danger' | 'success'; text: string }>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [isServerLoading, setIsServerLoading] = useState(false);
  const [isGenerateConfirmModalVisible, setIsGenerateConfirmModalVisible] = useState(false);
  const [isGenerateInfoModalVisible, setIsGenerateInfoModalVisible] = useState(false);
  const [generateInfo, setGenerateInfo] = useState<{ auth_server: string; auth_user: string; auth_code: string } | null>(null);
  const [userToGenerate, setUserToGenerate] = useState<User | null>(null);
  const [isPeriodModalVisible, setIsPeriodModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [startTime, setStartTime] = useState(moment('00:00', 'HH:mm'));
  const [endTime, setEndTime] = useState(moment('23:59', 'HH:mm'));
  const [isBindIpModalVisible, setIsBindIpModalVisible] = useState(false);
  const [ipList, setIpList] = useState<string[]>([]);
  const [selectedIp, setSelectedIp] = useState<string>('');
  const [userToBindIp, setUserToBindIp] = useState<User | null>(null);

  const removeToast = useCallback((removedToast: { id: string }) => {
    setToasts(toasts => toasts.filter(toast => toast.id !== removedToast.id));
  }, []);
  
  const addToast = useCallback((title: string, text: string, color: 'danger' | 'success' = 'danger') => {
    const id = new Date().toISOString();
    setToasts((toasts) => [
      ...toasts,
      {
        id,
        title,
        text,
        color,
      },
    ]);

    // 5秒后自动移除 toast
    setTimeout(() => {
      removeToast({ id });
    }, 5000);
  }, [removeToast]);

  const fetchServerInfo = useCallback(() => {
    api.get('/api/server/info')
      .then((res: any) => {
        if (res.data) {
          setServerStatus(res.data.status);
          setServerIp(res.data.ip);
        }
      })
      .catch((err: any) => {
        console.error('Error fetching server info:', err);
        addToast('Error', 'Failed to fetch server info. Please try again later.');
      });
  }, [addToast]);

  const fetchUsers = useCallback(() => {
    setIsLoading(true);
    api.get('/api/user/list')
      .then((res: any) => {
        if (res.data && res.data.code > 0) {
          setUsers(res.data.data);
        } else {
          addToast('Error', res.data.message || 'Failed to fetch user data', 'danger');
        }
      })
      .catch((err: any) => {
        console.error('Error fetching user data:', err);
        addToast('Error', 'Failed to fetch user data. Please try again later.', 'danger');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [addToast]);

  useEffect(() => {
    setUsername(getUsername());
    fetchServerInfo();
    fetchUsers();
  }, [fetchServerInfo, fetchUsers]);

  const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
  const closePopover = () => setIsPopoverOpen(false);

  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-label="User menu"
      onClick={onButtonClick}
    >
      <EuiAvatar name={username} size="s" />
    </EuiHeaderSectionItemButton>
  );

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const onTableChange = ({ page }: Criteria<User>) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page;
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    }
  };

  type User = {
    id: number;
    username: string;
    is_active: number;
    ip: string;
    assign_ip: string;
    device: string;
    block: number;
    period: string;
    created_at: string;
    is_admin: string;
  };

  const columns: Array<EuiBasicTableColumn<User>> = [
    {
      field: 'id',
      name: 'ID',
      sortable: true,
      render: (id: number) => (
        <EuiToolTip content={id.toString()}>
          <span>{id}</span>
        </EuiToolTip>
      ),
    },
    {
      field: 'username',
      name: 'Username',
      sortable: true,
      render: (username: string) => (
        <EuiToolTip content={username}>
          <span>{username}</span>
        </EuiToolTip>
      ),
    },
    {
      field: 'is_active',
      name: 'Status',
      render: (is_active: number) => (
        <EuiToolTip content={is_active === 1 ? 'Active' : 'Inactive'}>
          <EuiBadge color={is_active === 1 ? 'success' : 'danger'}>
            {is_active === 1 ? 'Active' : 'Inactive'}
          </EuiBadge>
        </EuiToolTip>
      ),
    },
    {
      field: 'ip',
      name: 'IP Address',
      render: (ip: string) => (
        <EuiToolTip content={ip}>
          <span>{ip}</span>
        </EuiToolTip>
      ),
    },
    {
      field: 'assign_ip',
      name: 'Assigned IP',
      render: (assign_ip: string) => (
        <EuiToolTip content={assign_ip || 'default'}>
          {assign_ip ? (
            <span>{assign_ip}</span>
          ) : (
            <EuiBadge color="default">default</EuiBadge>
          )}
        </EuiToolTip>
      ),
    },
    {
      field: 'device',
      name: 'Device',
      render: (device: string) => (
        <EuiToolTip content={device || '-'}>
          {device ? (
            <span>{device}</span>
          ) : (
            <EuiBadge color="default">-</EuiBadge>
          )}
        </EuiToolTip>
      ),
    },
    {
      field: 'block',
      name: 'Block',
      render: (block: number) => (
        <EuiToolTip content={block === 0 ? 'Unblocked' : 'Blocked'}>
          <EuiBadge color={block === 0 ? 'success' : 'danger'}>
            {block === 0 ? 'Unblocked' : 'Blocked'}
          </EuiBadge>
        </EuiToolTip>
      ),
    },
    {
      field: 'period',
      name: 'Period',
      render: (period: string) => (
        <EuiToolTip content={period || '-'}>
          <span>{period || '-'}</span>
        </EuiToolTip>
      ),
    },
    {
      field: 'created_at',
      name: 'Created At',
      sortable: true,
      render: (created_at: string) => (
        <EuiToolTip content={created_at}>
          <span>{created_at}</span>
        </EuiToolTip>
      ),
    },
    {
      field: 'is_admin',
      name: 'Role',
      render: (is_admin: string) => (
        <EuiToolTip content={is_admin === '1' ? 'Admin' : 'User'}>
          <EuiBadge color={is_admin === '1' ? 'primary' : 'default'}>
            {is_admin === '1' ? 'Admin' : 'User'}
          </EuiBadge>
        </EuiToolTip>
      ),
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Generate',
          description: 'Generate activation info for this user',
          icon: 'plusInCircle',
          type: 'icon',
          onClick: (user: User) => showGenerateConfirmModal(user),
        },
        {
          name: 'Bind IP',
          description: 'Bind IP to this user',
          icon: 'link',
          type: 'icon',
          onClick: (user: User) => showBindIpModal(user),
        },
        {
          name: 'Set Period',
          description: 'Set period for this user',
          icon: 'clock',
          type: 'icon',
          onClick: (user: User) => showPeriodModal(user),
        },
        {
          name: (user: User) => user.block === 1 ? 'Open' : 'Block',
          description: (user: User) => user.block === 1 ? 'Open this user' : 'Block this user',
          icon: 'lock',
          type: 'icon',
          onClick: (user: User) => {
            handleBlockToggle(user);
          },
        },
      ],
      width: '150px', // 设置列宽
      align: 'left', // 左对齐
    },
    {
      name: 'Delete',
      align: 'center',
      render: (user: User) => (
        <EuiButtonIcon
          iconType="trash"
          color="danger"
          aria-label="Delete"
          onClick={() => {
            if (user.is_admin === '1') {
              addToast('Warning', 'Cannot delete admin user', 'danger');
            } else {
              handleDeleteUser(user);
            }
          }}
        />
      ),
    },
  ];

  const findUsers = (users: User[], pageIndex: number, pageSize: number) => {
    let pageOfItems;

    if (!pageIndex && !pageSize) {
      pageOfItems = users;
    } else {
      const startIndex = pageIndex * pageSize;
      pageOfItems = users.slice(
        startIndex,
        Math.min(startIndex + pageSize, users.length)
      );
    }

    return {
      pageOfItems,
      totalItemCount: users.length,
    };
  };

  const { pageOfItems, totalItemCount } = findUsers(users, pageIndex, pageSize);

  const showAddModal = () => setIsAddModalVisible(true);
  const closeAddModal = () => {
    setIsAddModalVisible(false);
    setNewUsername('');
  };

  const handleAddUser = () => {
    if (!newUsername.trim()) {
      addToast('Error', 'Username cannot be empty', 'danger');
      return;
    }

    setIsLoading(true);
    api.post(`/api/user/add?username=${encodeURIComponent(newUsername)}`)
      .then((res: any) => {
        if (res.data && res.data.code > 0) {
          addToast('Success', res.data.message || 'User added successfully', 'success');
        } else {
          addToast('Error', res.data.message || 'Failed to add user', 'danger');
        }
      })
      .catch((err: any) => {
        addToast('Error', 'Failed to add user:'+err+' Please try again later.', 'danger');
      })
      .finally(() => {
        setIsLoading(false);
        closeAddModal();
        fetchUsers(); // 刷新用户列表
      });
  };

  let addModal;
  if (isAddModalVisible) {
    addModal = (
      <EuiModal onClose={closeAddModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Add New User</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiForm>
            <EuiFormRow label="Username">
              <EuiFieldText
                placeholder="Enter username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
              />
            </EuiFormRow>
          </EuiForm>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeAddModal}>Cancel</EuiButtonEmpty>
          <EuiButton onClick={handleAddUser} fill isLoading={isLoading}>
            Add User
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  const handleServerToggle = useCallback(() => {
    setIsServerLoading(true);
    const opt = serverStatus ? 'close' : 'open';
    api.post(`/api/server/opt?opt=${opt}`)
      .then((res: any) => {
        if (res.data && res.data.code > 0) {
          setServerStatus(!serverStatus);
          addToast('Success', `${opt} server successfully`, 'success');
          // 刷新服务器信息
          fetchServerInfo();
        } else {
          addToast('Error', `Failed to ${opt} server: ${res.data.message}`);
        }
      })
      .catch((err: any) => {
        console.error(`Error ${opt}ing server:`, err);
        addToast('Error', `Failed to ${opt} server. Please try again later.`);
      })
      .finally(() => {
        setIsServerLoading(false);
      });
  }, [serverStatus, addToast, fetchServerInfo]);

  const handleDeleteUser = useCallback((user: User) => {
    setIsLoading(true);
    api.post(`/api/user/del?username=${encodeURIComponent(user.username)}`)
      .then((res: any) => {
        if (res.data && res.data.code > 0) {
          addToast('Success', res.data.message || 'User deleted successfully', 'success');
          fetchUsers(); // 刷新用户列表
        } else {
          addToast('Error', res.data.message || 'Failed to delete user', 'danger');
        }
      })
      .catch((err: any) => {
        console.error('Error deleting user:', err);
        addToast('Error', 'Failed to delete user. Please try again later.', 'danger');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [addToast, fetchUsers]);

  const showGenerateConfirmModal = (user: User) => {
    setUserToGenerate(user);
    setIsGenerateConfirmModalVisible(true);
  };

  const closeGenerateConfirmModal = () => {
    setIsGenerateConfirmModalVisible(false);
    setUserToGenerate(null);
  };

  const handleGenerateConfirm = () => {
    closeGenerateConfirmModal();
    if (userToGenerate) {
      generateActivationInfo(userToGenerate);
    }
  };

  const generateActivationInfo = async (user: User) => {
    try {
      const response = await api.post(`api/user/active/info?user=${encodeURIComponent(user.username)}`);
      if (response.data && response.data.code === 1) {
        setGenerateInfo({
          auth_server: response.data.auth_server,
          auth_user: response.data.auth_user,
          auth_code: response.data.auth_code,
        });
        setIsGenerateInfoModalVisible(true);
      } else {
        addToast('Error', response.data.message || 'Failed to generate activation info', 'danger');
      }
    } catch (error) {
      console.error('Error generating activation info:', error);
      addToast('Error', 'Failed to generate activation info. Please try again later.', 'danger');
    }
  };

  const closeGenerateInfoModal = () => {
    setIsGenerateInfoModalVisible(false);
    setGenerateInfo(null);
  };

  // 修改 handleBlockToggle 函数
  const handleBlockToggle = useCallback((user: User) => {
    const action = user.block === 1 ? 'open' : 'block';
    api.post('/api/user/block', {
      username: user.username,
      opt: action
    })
      .then((res: any) => {
        if (res.data.code > 0) {
          addToast('Success', `User ${action}ed successfully`, 'success');
          fetchUsers(); // 刷新用户列表
        } else {
          addToast('Error', res.data.message || `Failed to ${action} user`, 'danger');
        }
      })
      .catch((err: any) => {
        console.error(`Error ${action}ing user:`, err);
        addToast('Error', `Failed to ${action} user. Please try again later.`, 'danger');
      });
  }, [addToast, fetchUsers]);

  const showPeriodModal = (user: User) => {
    setSelectedUser(user);
    setIsPeriodModalVisible(true);
  };

  const closePeriodModal = () => {
    setIsPeriodModalVisible(false);
    setSelectedUser(null);
  };

  const handlePeriodSubmit = async (reset: boolean = false) => {
    if (!selectedUser) return;

    try {
      const response = await api.post('/api/user/period', {
        username: selectedUser.username,
        period: reset ? "" : `${startTime.format('HH:mm')}-${endTime.format('HH:mm')}`
      });

      if (response.data && response.data.code > 0) {
        addToast('Success', reset ? 'Period reset successfully' : 'Period set successfully', 'success');
        fetchUsers(); // 刷新用户列表
        closePeriodModal();
      } else {
        addToast('Error', response.data.message || 'Failed to set period', 'danger');
      }
    } catch (error) {
      console.error('Error setting period:', error);
      addToast('Error', 'Failed to set period. Please try again later.', 'danger');
    }
  };

  const showBindIpModal = async (user: User) => {
    setUserToBindIp(user);
    setIsBindIpModalVisible(true);
    try {
      const response = await api.get('/api/server/iplist');
      if (response.data && response.data.code === 1) {
        setIpList(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedIp(response.data.data[0]);
        }
      } else {
        addToast('Error', 'Failed to fetch IP list', 'danger');
      }
    } catch (error) {
      console.error('Error fetching IP list:', error);
      addToast('Error', 'Failed to fetch IP list. Please try again later.', 'danger');
    }
  };

  const closeBindIpModal = () => {
    setIsBindIpModalVisible(false);
    setUserToBindIp(null);
    setSelectedIp('');
  };

  const handleBindIp = async () => {
    if (!userToBindIp) return;
    try {
      const response = await api.post('/api/user/assip', {
        username: userToBindIp.username,
        ip: selectedIp,
      });
      if (response.data && response.data.code > 0) {
        addToast('Success', 'IP bound successfully', 'success');
        fetchUsers(); // 刷新用户列表
        closeBindIpModal();
      } else {
        addToast('Error', response.data.message || 'Failed to bind IP', 'danger');
      }
    } catch (error) {
      console.error('Error binding IP:', error);
      addToast('Error', 'Failed to bind IP. Please try again later.', 'danger');
    }
  };

  const handleResetIp = async () => {
    if (!userToBindIp) return;
    try {
      const response = await api.post('/api/user/assip', {
        username: userToBindIp.username,
        ip: '',
      });
      if (response.data && response.data.code > 0) {
        addToast('Success', 'IP reset successfully', 'success');
        fetchUsers(); // 刷新用户列表
        closeBindIpModal();
      } else {
        addToast('Error', response.data.message || 'Failed to reset IP', 'danger');
      }
    } catch (error) {
      console.error('Error resetting IP:', error);
      addToast('Error', 'Failed to reset IP. Please try again later.', 'danger');
    }
  };

  return (
    <EuiPageTemplate direction='column' style={{ height: "100vh" }}>
      <EuiPortal>
        <div style={{
          position: 'fixed',
          top: '60px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 9999,
          width: '400px', // 设置一个固定宽度
        }}>
          {toasts.map((toast) => (<EuiToast key={toast.id} title={toast.title} color={toast.color} iconType="check" onClose={() => removeToast(toast)}><p>{toast.text}</p></EuiToast>))}
        </div>
      </EuiPortal>
      <EuiHeader
        theme="dark"
        sections={[
          {
            items: [
              <EuiText color="ghost"><h4>NB_NET</h4></EuiText>,
              <EuiHeaderLinks style={{ marginLeft: 20 }} aria-label="App">
              </EuiHeaderLinks>,
            ],
          },
          {
            items: [
              <EuiPopover
                button={button}
                isOpen={isPopoverOpen}
                closePopover={closePopover}
                panelPaddingSize="xs"
                anchorPosition="downRight"
              >
                <EuiFlexGroup direction="column">
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup alignItems="center" gutterSize="s">
                      <EuiFlexItem style={{ marginRight: 10, marginLeft: 10 }} >
                        <EuiAvatar name={username} size="xl" />
                      </EuiFlexItem>
                      <EuiFlexGroup direction="column" gutterSize="m">
                        <EuiFlexItem>
                          <EuiText style={{ marginTop: "10px" }}>
                            <p>
                              <strong>{username}</strong>
                            </p>
                          </EuiText>
                        </EuiFlexItem>

                        <EuiFlexGroup direction="row" alignItems='center'>
                          <EuiFlexItem grow={false}>
                            <EuiText>
                              <EuiLink disabled ><p>Profile</p></EuiLink>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiText>
                              <EuiLink onClick={() => handleLogout()} style={{ width: 100, textAlign: "right", marginRight: 10 }}><p>Log out</p></EuiLink>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexGroup>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPopover>,
            ],
          },
        ]}
      />
      <EuiPageHeader style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20 }} paddingSize='m' bottomBorder>
        <EuiFlexGroup justifyContent='flexStart' direction='row' alignItems='center'>
          <EuiFlexItem>
            <EuiTitle >
              <p>ServerInfo</p>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexGroup direction='row' justifyContent='spaceAround' alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiStat
                titleSize='s'
                title={
                  <EuiBadge
                    style={{ fontSize: "1.2rem" }}
                    color={serverStatus ? "#67c23a" : "#bd271e"}
                  >
                    {serverStatus ? "running" : "stop"}
                  </EuiBadge>
                }
                description="Status"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiStat titleSize='s' title={serverIp} description="IP" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiStat titleSize='s' title="dy-wk-cn.ssfox.io" description="Entrance_CN" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <div style={{ borderRight: '1px solid rgb(211, 218, 230)', height: '45px' }}></div>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                size='m'
                iconType={serverStatus ? "warningFilled" : "play"}
                color={serverStatus ? "danger" : "success"}
                fill
                onClick={handleServerToggle}
                isLoading={isServerLoading}
              >
                {serverStatus ? "CLOSE" : "OPEN"}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiPageHeader>
      <EuiPanel paddingSize='m' style={{ margin: 15 }}>
        <EuiFlexGroup direction='row' justifyContent='spaceEvenly' alignItems="center">
          <EuiFlexItem>
            <EuiTitle><h2>UserList</h2></EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              iconType="refresh"
              onClick={fetchUsers}
              isDisabled={isLoading}
              aria-label="Refresh user list"
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton size='m' iconType={"plus"} color='primary' onClick={showAddModal}>
              Add
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />
        <EuiHorizontalRule margin="none" style={{ height: 2 }} />
        {isLoading ? (
          <EuiFlexGroup justifyContent="center" alignItems="center" style={{ height: '200px' }}>
            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner size="xl" />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiBasicTable
            items={pageOfItems}
            columns={columns}
            pagination={{
              pageIndex: pageIndex,
              pageSize: pageSize,
              totalItemCount: totalItemCount,
              pageSizeOptions: [10, 20, 50],
            }}
            onChange={onTableChange}
          />
        )}
      </EuiPanel>
      {addModal}

      {isGenerateConfirmModalVisible && (
        <EuiOverlayMask>
          <EuiConfirmModal title="Generate Activation Info"
            onCancel={closeGenerateConfirmModal}
            onConfirm={handleGenerateConfirm}
            cancelButtonText="Cancel"
            confirmButtonText="Continue"
            defaultFocusedButton="confirm"
          >
            <p>生成激活信息后请及时保存，激活信息只可使用一次，用户只能生成一次激活信息。</p>
          </EuiConfirmModal>
        </EuiOverlayMask>
      )}

      {isGenerateInfoModalVisible && generateInfo && (
        <EuiOverlayMask>
          <EuiModal onClose={closeGenerateInfoModal} maxWidth={500}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>激活信息</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiText>
                <p>请复制并保存以下信息，关闭后将无法再次查看。</p>
              </EuiText>
              <EuiSpacer size="m" />
              <EuiCodeBlock
                language="text"
                fontSize="m"
                paddingSize="m"
                isCopyable={true}
              >
                {`服务器:${generateInfo.auth_server}\n用户:${generateInfo.auth_user}\n激活码:${generateInfo.auth_code}`}
              </EuiCodeBlock>
              <EuiSpacer size="s" />
              <EuiSpacer size="l" />
              <EuiText size="s">
                <p><strong>注意：</strong> 这些信息只显示一次，请务必妥善保存。</p>
              </EuiText>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiFlexGroup justifyContent="center" alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={closeGenerateInfoModal} fill style={{ minWidth: '200px' }}>
                    关闭
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}

      {isPeriodModalVisible && selectedUser && (
        <EuiModal onClose={closePeriodModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>设置开放时间</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFormRow label="开始时间">
              <EuiDatePicker
                showTimeSelect
                showTimeSelectOnly
                selected={startTime}
                onChange={(date: moment.Moment | null) => date && setStartTime(date)} dateFormat="HH:mm" timeFormat="HH:mm"
              />
            </EuiFormRow>
            <EuiFormRow label="结束时间">
              <EuiDatePicker
                showTimeSelect
                showTimeSelectOnly
                selected={endTime}
                onChange={(date: moment.Moment | null) => date && setEndTime(date)} dateFormat="HH:mm" timeFormat="HH:mm"
              />
            </EuiFormRow>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiFlexGroup justifyContent="center" alignItems="center">
              <EuiFlexItem grow={false}>
                <EuiButton onClick={closePeriodModal}>取消</EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => handlePeriodSubmit(true)} color="danger">
                  重置
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => handlePeriodSubmit(false)} fill>
                  确认
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalFooter>
        </EuiModal>
      )}

      {isBindIpModalVisible && userToBindIp && (
        <EuiOverlayMask>
          <EuiModal onClose={closeBindIpModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>绑定IP</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiFormRow label="选择IP">
                <EuiSelect
                  options={ipList.map(ip => ({ value: ip, text: ip }))}
                  value={selectedIp}
                  onChange={(e) => setSelectedIp(e.target.value)}
                />
              </EuiFormRow>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButton onClick={closeBindIpModal}>取消</EuiButton>
              <EuiButton onClick={handleResetIp} color="danger">重置</EuiButton>
              <EuiButton onClick={handleBindIp} fill>绑定</EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
    </EuiPageTemplate>
  );
};

export default HomePage;